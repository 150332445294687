import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

import HomeScreen from 'components/HomeScreen';
import BusinessScreen from 'components/BusinessScreen';
import ContactScreen from 'components/ContactScreen';
import SiteNavbar from 'components/SiteNavbar';
import SiteFooter from 'components/SiteFooter';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const SiteScroll = withRouter(ScrollToTop);

function App() {
  return (
    <Router>
        <SiteScroll>
          <SiteNavbar />

          <Route exact path="/" component={HomeScreen} />
          <Route path="/#about" component={HomeScreen} />
          <Route path="/business" component={BusinessScreen} />
          <Route path="/contact" component={ContactScreen} />

          <SiteFooter />
        </SiteScroll>

    </Router>
  );
}

export default App;
