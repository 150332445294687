import { combineReducers } from 'redux'
import { SUBSCRIBE_SENDING, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAILURE,
         MESSAGE_SENDING, MESSAGE_SUCCESS, MESSAGE_FAILURE } from '../actions'

const initialState = {
    subscribe: {
        requesting: false,
        success: false,
        message: null,
        error: false
    },
    message: {
        sending: false,
        success: false,
        error: false,
        message: null
    }
    
}

function subscribe(state = initialState.subscribe, action) {

    switch (action.type) {
      case SUBSCRIBE_SENDING:
        return {
          ...state,
          requesting: true,
          error: false,
          success: false,
          message: "",
        }
      case SUBSCRIBE_SUCCESS:
        return {
          ...state,
          requesting: false,
          success: true,
          message: action.payload.message
        }
      case SUBSCRIBE_FAILURE:
        return {
          ...state,
          requesting: false,
          error: true,
          message: action.payload.error
        }
      default:
        return state
    }
}

function message(state = initialState.message, action) {
    switch (action.type) {
      case MESSAGE_SENDING:
        return {
          ...state,
          sending: true,
          success: false,
          error: false,
          message: null
        }
      case MESSAGE_SUCCESS:
        return {
          ...state,
          sending: false,
          success: true,
          error: false,
          message: action.payload.message
        }
      case MESSAGE_FAILURE:
        return {
          ...state,
          sending: false,
          success: false,
          error: true,
          message: action.payload.error
        }
      default:
        return state;
    }

}





export default combineReducers({
  subscribe, message
})
