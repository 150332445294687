import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types'
import logo from 'images/logo.png'

import $ from 'jquery';

import './SiteNavbar.css';

class SiteNavbar extends Component {
  static propTypes = {

  }

  render() {
    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top navbar-solid">
            <Link className="navbar-brand" to="/">
                <img src={logo} alt="Sailize" />
            </Link>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#nbResponsive">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="nbResponsive">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
                    <li className="nav-item"><Link to="/#about" smooth className="nav-link">About</Link></li>
                    <li className="nav-item"><Link to="/business" className="nav-link">For Business</Link></li>
                    <li className="nav-item"><Link to="/contact" className="nav-link">Contact</Link></li>
                        
                </ul>
            </div>
        </nav>
    )
  }
}


$(document).ready(function() {
    function applyTransparence () {
        $('.navbar').addClass('navbar-solid');

        if ( $('.landing').length ){
            var scrollTop = $(window).scrollTop();

            if (scrollTop >= 70) {
                $('.navbar').addClass('navbar-solid');
            } else {
                $('.navbar').removeClass('navbar-solid');
            }

        }
    };

    applyTransparence();

    $(window).scroll(function() {
        applyTransparence();

    });
});


export default SiteNavbar
