import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doSubscribe } from '../actions'
import PropTypes from 'prop-types'

import { faMapMarked, faUsers, faCompass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './HomeScreen.css';

function SendingData (props) {
    const {sending} = props;

    if (sending) {
        return (
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        )
    }else{
        return null;
    }
}

function Error (props) {
    const { subscribe } = props;

    if ( subscribe.error ) {
        return (
            <div class="row justify-content-md-center">
                <div class="col-md-6">
                <div class="alert alert-danger" role="alert">{subscribe.message}</div>
                </div>
            </div>
        )
    }else {
        return null;
    }
}

function Success (props) {
    let { subscribe } = props;
    
    if ( subscribe.success ) {
        return (
            <div class="row justify-content-md-center">
                <div class="col-md-6">
                <div class="alert alert-success" role="alert">{subscribe.message}</div>
                </div>
            </div>
        )
    }else {
        return null;
    }
}


class HomeScreen extends Component {
    state = {
        "email":"",
        "validEmail": false
    };

    constructor(props) {
        super(props);

        this.doSubscribe = this.doSubscribe.bind(this);
        this.handleEmail = this.handleEmail.bind(this);

    }

    doSubscribe(e) {
        e.preventDefault();
        this.props.doSubscribe(this.state.email);

    }

    handleEmail(e) {        
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let validEmail = e.target.value.match(mailformat);

        this.setState({"email": e.target.value, "validEmail": validEmail });

    }


  render() {
    let { email, validEmail } = this.state;
    let { subscribe } = this.props;

    return (
    <div id="home">
        
        <div class="landing">
            <div class="home-wrap">
                <div class="home-inner">
                </div>
            </div>
        </div>

        <div class="caption text-center">
            <h1>Welcome to Sailize</h1>
            <h3>Come sail away together.</h3>
            <a href="#about" class="btn btn-outline-light btn-lg">More About</a>
        </div>


        <div id="about" class="offset">

            <div class="jumbotron">
                <div class="narrow text-center">
                    <div class="col-12">
                        <h3 class="heading">About</h3>
                        <div class="heading-underline"></div>
                        <div>
                            <h5>Sailize offers a collection of features to connect you in the sailing world.</h5>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-md-4">
                            <div class="feature">
                                <FontAwesomeIcon icon={faMapMarked} class='feature-logo'/>
                                <h3>Planning Trips</h3>
                                <p>Do you have a new plan to sailing? Great! Plan your next trip and invite a crew  to share this new experience on board.</p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="feature">
                                <FontAwesomeIcon icon={faUsers} class='feature-logo'/>
                                <h3>Follow Friends</h3>
                                <p>Share your position where you are sailing and follow your favorites sailors. Keep in touch and share your experience with everyone. </p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="feature">
                                <FontAwesomeIcon icon={faCompass}  class='feature-logo'/>
                                <h3>Be Safe</h3>
                                <p>Know the best tips and places to sailing safaly. Everybody connected and taking care of each other.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div id="subscribe" class="container offset">
            <div class="col-md-12 narrow text-center">
                <h1>Newsletter</h1>
                <p class="lead">Sailize is not just another app. It's about how we can enjoy our life better. Are you ready?</p>
                
                <form onSubmit={this.doSubscribe}>
                    <div class="container">
                        <div class="row justify-content-md-center">
                            <div class="col-md-5">
                                <input type="email" onChange={this.handleEmail} value={email} class="form-control form-control-lg" id="email" placeholder="Enter your email contact..." />
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-secondary form-control-lg" disabled={!validEmail && !subscribe.requesting}>Subscribe</button>
                                
                            </div>
                        </div>
                        <SendingData sending={subscribe.requesting} />
                        <Error subscribe={subscribe}/>
                        <Success subscribe={subscribe}/>
                    </div>

                </form>
            </div>

        </div>

    </div>
    )
  }
}
const mapStateToProps = (state) => ({
    subscribe: state.subscribe,
})
const mapDispatchToProps = (dispatch) => ({
    doSubscribe: (email) => dispatch(doSubscribe(email)),
})

HomeScreen = connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

export default HomeScreen
