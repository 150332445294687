import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { faSatellite, faCode, faServer } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './BusinessScreen.css'

class BusinessScreen extends Component {
  static propTypes = {

  }

  render() {
    return (
      <div id="projects" class="offset">

        <div class="fixed-background business">
            <div class="row layer-colored text-center">
                <div class="col-12">
                    <h3 class="heading">How can Sailize helps your business?</h3>
                    <div class="heading-underline"></div>
                </div>

                <div class="col-md-4">
                    <h3>Technologies</h3>
                    <div class="feature">
                        <FontAwesomeIcon icon={faSatellite} class="feature-icon"/>
                    </div>
                    <p class="feature-lead">Sailize can helps your business to choose the best technology solution for your needs.</p>
                </div>

                <div class="col-md-4">
                    <h3>Services</h3>
                    <div class="feature">
                        <FontAwesomeIcon icon={faServer} class="feature-icon"/>
                    </div>
                    <p class="feature-lead">Best services and control for your business reflects in quality for your customers.</p>
                </div>


                <div class="col-md-4">
                    <h3>Outsourcing</h3>
                    <div class="feature">
                        <FontAwesomeIcon icon={faCode} class="feature-icon"/>
                    </div>
                    <p class="feature-lead">Not satisfied? Sailize can build the best solution for your business exactly as you need.</p>
                </div>

            </div>

            <div class="fixed-wrap">
                <div class="fixed">
                </div>
            </div>
        </div>

    </div>
    )
  }
}

export default BusinessScreen
