import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logo from 'images/logo.png'

class SiteFooter extends Component {
  static propTypes = {

  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
        <div id="contact" class="offset">
            <footer>
                <div class="row justify-content-center">
                    <div class="col-md-5 text-center">
                        <img src={logo} alt="" class="logo" />
                        <p>Come sail away together<br/>
                        </p>
                        <strong>Contact Us</strong>
                        <p>421 Broadway PMB 22944 &middot; San Diego - CA &middot; 92101 
                            <br/>(619) 319-1005
                            <br/>contact@sailize.com
                        </p>

                        <a href="#"><i class="fab fa-facebook-square"></i></a>
                        <a href="#"><i class="fab fa-twitter-square"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                            
                        <hr class="socket" />
                        <p>&copy; Sailize, LLC {this.getYear()}</p>
                        
                    </div>
                    
                </div>

            </footer>

        </div>
    )
  }
}

export default SiteFooter
