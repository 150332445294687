// import { call, put, takeEvery } from 'redux-saga/effects';

const SERVER_NAME = "https://cxwkegzeta.execute-api.us-west-2.amazonaws.com/sailize";
// const SERVER_NAME = "http://localhost:8000";

export function postSubscribe (email) {
        return new Promise(resolve => {
            const URL = SERVER_NAME + "/webapi/contacts/subscribe";
            const options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    email_address: email
                })
            };

            fetch(URL, options)                
                .then(res => res.json())
                .then(res => {
                    resolve(res)
                })
                .catch(error =>{
                    resolve({
                        success: false,
                        error: error.Message
                    });
                })
                

        })

        /*

        const URL = "https://sailize.us4.list-manage.com/subscribe/post?u=029656b0dc1f94e4bd5f733da&amp;id=3ff25c1133";
        
        const response = yield call(fetch, URL, {method: 'POST'});
        console.log("Response: ", response);
        const data = yield response.json();

        return data;
        */
};


export function postSendMessage (contact) {
    return new Promise(resolve => {
        let {name, email, subject, description} = contact;
        const URL = SERVER_NAME + "/webapi/messages/sendmessage";
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                name, email, subject, description
            })
        };
        console.log(options);
        fetch(URL, options)                
        .then(res => res.json())
        .then(res => {
            resolve(res)
        })
        .catch(error =>{
            resolve({
                success: false,
                error: error.Message
            });
        })
            

    })
};