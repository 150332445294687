export const SUBSCRIBE_SENDING = "SUBSCRIBE_SENDING";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_FAILURE = "SUBSCRIBE_FAILURE";

export const MESSAGE_SENDING = "MESSAGE_SENDING";
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_FAILURE = "MESSAGE_FAILURE";

// Subscription

export function doSubscribe(email) {
    return {
        type: SUBSCRIBE_SENDING,
        email
    }
}

export function subscribeSuccess(message) {
    return {
        type: SUBSCRIBE_SUCCESS,
        payload: message
    }
    
}

export function subscribeError(error) {
    return {
        type: SUBSCRIBE_FAILURE,
        payload: error
    }
}

// Send Message

export function doSendMessage(contact) {
    return {
        type: MESSAGE_SENDING,
        contact
    }
}

export function messageSuccess(message) {
    return {
        type: MESSAGE_SUCCESS,
        payload: message
    }
}

export function messageError(error) {
    return {
        type: MESSAGE_FAILURE,
        payload: error
    }
}