import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { doSendMessage } from '../actions'


import './ContactScreen.css';

function SendingData (props) {
  const {sending} = props;

  if (sending) {
      return (
          <div class="spinner-grow spinner-grow-lg" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      )
  }else{
      return null;
  }
}

function Error (props) {
  const { message } = props;

  if ( message.error ) {
      return (
          <div class="row justify-content-md-center">
              <div class="col-md-6">
              <div class="alert alert-danger" role="alert">{message.message}</div>
              </div>
          </div>
      )
  }else {
      return null;
  }
}

function Success (props) {
  let { message } = props;
  
  if ( message.success ) {
      return (
          <div class="row justify-content-md-center">
              <div class="col-md-6">
              <div class="alert alert-success" role="alert">{message.message}</div>
              </div>
          </div>
      )
  }else {
      return null;
  }
}

class ContactScreen extends Component {
  static propTypes = {

  }

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      description: ""

    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  sendMessage(event) {
    let {name, email, subject, description} = this.state;

    let contact = {name, email, subject, description};

    this.props.doSendMessage(contact);
    event.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value 
    });
  }

  render() {
    let {name, email, subject, description} = this.state;
    let { message } = this.props;
    

    return (
      <div id="projects" class="offset">

        <div class="fixed-background contact">
          <div class="row layer-colored text-center">
              <div class="col-12">
                <h3 class="heading">Contact</h3>
                <div class="heading-underline"></div>
              </div>

              <div class="fixed-wrap">
                <div class="fixed">
              </div>

            </div>
          </div>
        </div>

        <div class="row justify-content-md-center form-body">
          <div class="col-md-6">
            <form onSubmit={this.sendMessage}>
              <div class="form-row">
                  <label for="name">Name</label>
                  <input type="text" class="form-control form-control-lg" name="name" value={name} onChange={this.handleInputChange} placeholder="Your name" required/>
              </div>
              <div class="form-row">
                  <label for="email">Email</label>
                  <input type="email" class="form-control form-control-lg" name ="email" value={email} onChange={this.handleInputChange} placeholder="Email" required/>
              </div>
              <div class="form-row">
                <label for="subject">Subject</label>
                <input type="text" class="form-control form-control-lg" name="subject"  value={subject} onChange={this.handleInputChange} placeholder="Subject" required/>
              </div>
              <div class="form-row">
                <label for="inputAddress2">Message</label>
                <textarea rows="7" class="form-control form-control-lg description-box" name="description" value={description} onChange={this.handleInputChange} placeholder="Your message..." required></textarea>
              </div>
              <div class="form-row justify-content-md-center">
                <button type="submit" class="btn btn-primary btn-lg">Send Message</button>
              </div>
              <div class="form-row justify-content-md-center">
                <SendingData sending={message.sending} />
                <Error message={message}/>
                <Success message={message}/>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
})
const mapDispatchToProps = (dispatch) => ({
  doSendMessage: (contact) => dispatch(doSendMessage(contact)),
})

ContactScreen = connect(mapStateToProps, mapDispatchToProps)(ContactScreen);

export default ContactScreen
