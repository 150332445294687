import { take, put, call, fork, select, takeEvery, all } from 'redux-saga/effects'
import * as actions from '../actions'
import { postSubscribe, postSendMessage } from '../services'

export function* fetchSubscribe(params) {
    try {
        // const result = yield call(api.postSubscribe, params.email)
        const response = yield postSubscribe(params.email);
                            

        if ( response.success ) {
            yield put(actions.subscribeSuccess({message: response.message}));

        } else {
            yield put(actions.subscribeError({error: response.message}));
        }

    } catch (error) {
        yield put(actions.subscribeError({error}));
    }
}

export function* fetchSendMessage(params) {
    try {
        const response = yield postSendMessage(params.contact);

        if (response.success) {
            yield put(actions.messageSuccess({message: response.message}));

        } else {
            yield put(actions.messageError({error: response.message}));
        }
    } catch (error) {
        yield put(actions.messageError({error}));
    }
}

// export function* checkout() {
//   try {
//     const cart = yield select(getCart)
//     yield call(api.buyProducts, cart)
//     yield put(actions.checkoutSuccess(cart))
//   } catch (error) {
//     yield put(actions.checkoutFailure(error))
//   }
// }

export function* actionWatcher() {
    yield takeEvery(actions.SUBSCRIBE_SENDING, fetchSubscribe);
    yield takeEvery(actions.MESSAGE_SENDING, fetchSendMessage);
}



export default function* root() {
    yield all([fork(actionWatcher)])
}